import type { FetchOptions } from 'ofetch';
import { type AsyncDataOptions, useAsyncData } from '#app';
import FetchFactory from '../factory';
import type { StudentPreserveParams } from '~/types/params';

class StudentModule extends FetchFactory<unknown> {
    private RESOURCE = '/users'

    private fetchOptions: FetchOptions<'json'> = {
        headers: {
            'Accept-Language': 'en-US',
            'Content-Type': 'application/json'
        }
    }


    async getStudents(params: { pageNumber: number; pageSize: number; search: string, filters: { key: string; value: string }[] }, asyncDataOptions?: AsyncDataOptions<unknown>) {
        const queryParams = new URLSearchParams({
            pageNumber: params.pageNumber.toString(),
            pageSize: params.pageSize.toString(),
            searchText: params.search
        });

        if (params.filters) {
            // Group filters by key to combine values
            const groupedFilters = params.filters.reduce((acc, filter) => {
                if (!acc[filter.key]) {
                    acc[filter.key] = [];
                }
                acc[filter.key].push(filter.value);
                return acc;
            }, {} as Record<string, string[]>);

            // Add grouped filters to query params
            Object.entries(groupedFilters).forEach(([key, values], index) => {
                queryParams.append(`Filters[${index}].PropertyName`, key);
                queryParams.append(`Filters[${index}].Operator`, 'Equals');
                values.forEach(value => {
                    queryParams.append(`Filters[${index}].Values`, value);
                });
            });
        }
        queryParams.append('SortOption.ColumnName', 'createDate');
        queryParams.append('SortOption.IsDescending', 'true');

        return useAsyncData(
            'getStudents',
            () => {
                return this.call(
                    'GET',
                    `${this.RESOURCE}?${queryParams.toString()}`,
                    undefined,
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }

    async getStudentById(id: string, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'getStudentById',
            () => this.call('GET', `${this.RESOURCE}/${id}`, undefined, this.fetchOptions),
            asyncDataOptions
        )
    }

    async getStudentVideoUrl(id: string, videoName: string, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'getStudentVideoUrl',
            () => {
                return this.call(
                    'GET',
                    `${this.RESOURCE}/${id}/videos/${videoName}`,
                    undefined,
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }

    async getIndividualStudentVideoUrl(id: string, videoName: string, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'getIndividualStudentVideoUrl',
            () => {
                return this.call(
                    'GET',
                    `${this.RESOURCE}/me/videos/${videoName}`,
                    undefined,
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }

    async approveStudent(id: string, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'approveStudent',
            () => {
                return this.call(
                    'POST',
                    `${this.RESOURCE}/approve`,
                    { id },
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }

    async approveStudentBack(params: { id: string; preserveEndDate: string }, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'approveStudentBack',
            () => {
                return this.call(
                    'POST',
                    `${this.RESOURCE}/approve-preserve-end`,
                    params,
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }

    async rejectStudent(id: string, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'rejectStudent',
            () => {
                return this.call(
                    'POST',
                    `${this.RESOURCE}/reject`,
                    { id },
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }

    async reserveStudent(params: StudentPreserveParams, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'reserveStudent',
            () => this.call('POST', `${this.RESOURCE}/preserve`, { ...params }, this.fetchOptions),
            asyncDataOptions
        )
    }

}

export default StudentModule
