import type { FetchOptions } from 'ofetch';
import { type AsyncDataOptions, useAsyncData } from '#app';
import FetchFactory from '../factory';
import type { LoginFormType } from '@/types/auth';

class AuthModule extends FetchFactory<unknown> {
    private RESOURCE = '/users'

    private fetchOptions: FetchOptions<'json'> = {
        headers: {
            'Accept-Language': 'en-US',
            'Content-Type': 'application/json'
        }
    }

    async login(credentials: LoginFormType, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'login',
            () => {
                return this.call(
                    'POST',
                    `${this.RESOURCE}/login`,
                    credentials,
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }

    async getProfile(asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'getProfile',
            () => {
                return this.call(
                    'GET',
                    `${this.RESOURCE}/me`,
                    undefined,
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }

    async checkEmail(email: string, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'checkEmail',
            () => {
                return this.call(
                    'GET',
                    `${this.RESOURCE}/check-email?email=${email}`,
                    undefined,
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }

    async register(data: FormData, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'register',
            () => {
                return this.call(
                    'POST',
                    `${this.RESOURCE}/register`,
                    undefined,
                    {
                        headers: {
                            Accept: '*/*'
                        },
                        body: data
                    }
                )
            },
            asyncDataOptions
        )
    }

    async updateProfileStudentForAdmin(data: FormData, asyncDataOptions?: AsyncDataOptions<unknown>) {
        const studentId = data.get('id')
        if (!studentId) {
            throw new Error('Student ID is required')
        }
        return useAsyncData(
            'updateProfile',
            () => {
                return this.call(
                    'PUT',
                    `${this.RESOURCE}/${studentId}`,
                    undefined,
                    {
                        headers: {
                            Accept: '*/*'
                        },
                        body: data
                    }
                )
            },
            asyncDataOptions
        )
    }

    async updateProfile(data: FormData, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'updateProfile',
            () => {
                return this.call(
                    'PUT',
                    `${this.RESOURCE}`,
                    undefined,
                    {
                        headers: {
                            Accept: '*/*'
                        },
                        body: data
                    }
                )
            },
            asyncDataOptions
        )
    }

    async resendRequest(asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'resendRequest',
            () => {
                return this.call(
                    'POST',
                    `${this.RESOURCE}/resend-request`,
                    undefined,
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }

    async refreshToken(asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'refreshToken',
            () => {
                return this.call(
                    'POST',
                    `${this.RESOURCE}/refresh-token`,
                    {},
                    this.fetchOptions
                )
            },
            asyncDataOptions
        )
    }

    async cancelReserve(id: number, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'preserve-remove',
            () => {
                return this.call('POST', `${this.RESOURCE}/preserve-remove`, { id }, this.fetchOptions)
            },
            asyncDataOptions
        )
    }
}

export default AuthModule