import type { FetchOptions } from 'ofetch';
import { type AsyncDataOptions, useAsyncData } from '#app';
import FetchFactory from '../factory';
import type { UserMeasurementsParams } from '~/types';

class UserMeasurementModule extends FetchFactory<unknown> {
    private RESOURCE = '/user-measurements'

    private fetchOptions: FetchOptions<'json'> = {
        headers: {
            'Accept-Language': 'en-US',
            'Content-Type': 'application/json'
        }
    }

    async createWeekMeasurements(params: FormData, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'createWeekMeasurements',
            () => {
                return this.call(
                    'POST',
                    `${this.RESOURCE}`,
                    undefined,
                    {
                        headers: {
                            Accept: '*/*'
                        },
                        body: params
                    }
                )
            },
            asyncDataOptions
        )
    }

    async updateWeekMeasurements(params: FormData, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'updateWeekMeasurements',
            () => {
                return this.call(
                    'PUT',
                    `${this.RESOURCE}`,
                    undefined,
                    {
                        headers: {
                            Accept: '*/*'
                        },
                        body: params
                    }
                )
            },
            asyncDataOptions
        )
    }

    async createWeeklyReportForAdmin(studentId: string, data: FormData, asyncDataOptions?: AsyncDataOptions<unknown>) {
        // const studentId = data.get('id')
        if (!studentId) {
            throw new Error('Student ID is required')
        }
        return useAsyncData(
            'createWeeklyReportAdmin',
            () => {
                return this.call(
                    'POST',
                    `${this.RESOURCE}/admin/${studentId}`,
                    undefined,
                    {
                        headers: {
                            Accept: '*/*'
                        },
                        body: data
                    }
                )
            },
            asyncDataOptions
        )
    }

    async updateWeeklyReportForAdmin(data: FormData, asyncDataOptions?: AsyncDataOptions<unknown>) {
        // const studentId = data.get('id')
        // if (!studentId) {
        //     throw new Error('Student ID is required')
        // }
        return useAsyncData(
            'updateWeeklyReportAdmin',
            () => {
                return this.call(
                    'PUT',
                    `${this.RESOURCE}/admin`,
                    undefined,
                    {
                        headers: {
                            Accept: '*/*'
                        },
                        body: data
                    }
                )
            },
            asyncDataOptions
        )
    }

    async getStudentWeekMeasurements(id: number, asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'getStudentWeekMeasurements',
            () => {
                return this.call('GET', `${this.RESOURCE}/users/${id}`, undefined, this.fetchOptions)
            },
            asyncDataOptions
        )
    }

    async getMyWeekMeasurements(asyncDataOptions?: AsyncDataOptions<unknown>) {
        return useAsyncData(
            'getMyWeekMeasurements',
            () => {
                return this.call('GET', `${this.RESOURCE}/me`, undefined, this.fetchOptions)
            },
            asyncDataOptions
        )
    }
}

export default UserMeasurementModule