import { useNuxtApp, type AsyncDataOptions } from '#app'
import type {
  LoginFormType,
  ErrorData,
  TokenData,
  LoginResponse,
  ApiResult
} from '~/types'
import { token } from '~/data/local'
import { useMyAuthStore } from '~/stores/auth.store'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import type { FormInstance } from 'element-plus'

const asyncDataOptions: AsyncDataOptions<unknown> = {
  server: false
} as const

export const useLoginContext = () => {
  const { $api } = useNuxtApp()
  const authStore = useMyAuthStore()
  const router = useRouter()
  const loading = ref(false)
  const errorMessage = ref('')

  const loginHandler = (params: LoginFormType) =>
    $api.auth.login(params, asyncDataOptions)
  const getProfileHandler = () => $api.auth.getProfile()

  const setToken = async (tokenData: TokenData) => {
    const tokenPref = token()
    await tokenPref.set(JSON.stringify(tokenData))
  }

  const setProfileToStore = (profile: LoginResponse) => {
    authStore.setProfile(profile)
  }

  const handleLoginError = (errorData: ErrorData | undefined) => {
    if (!errorData) {
      errorMessage.value = 'Đã xảy ra lỗi. Vui lòng thử lại.'
      return
    }

    if (errorData.errors) {
      const firstErrorKey = Object.keys(errorData.errors)[0]
      errorMessage.value =
        errorData.errors[firstErrorKey as keyof typeof errorData.errors][0]
    } else if (errorData.title) {
      errorMessage.value = errorData.title
    } else {
      errorMessage.value = 'Đã xảy ra lỗi. Vui lòng thử lại.'
    }
  }

  const handleSuccessfulLogin = async () => {
    const profileResult = await getProfileHandler()
    const profile = profileResult.data.value as LoginResponse

    if (!profile) return

    await setProfileToStore(profile)

    const redirectPath =
      authStore.isAdmin || authStore.isEmployee
        ? '/admin/students'
        : '/student/profile'

    await router.push(redirectPath)
  }

  const validateLoginForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return false
    return await formEl.validate()
  }

  const processLoginResponse = async (result: ApiResult<TokenData>) => {
    if (result.error?.value) {
      handleLoginError(result.error.value.data)
      return false
    }

    const tokenData = result.data.value
    if (!tokenData) {
      handleLoginError(result.error?.value?.data)
      return false
    }

    await setToken(tokenData)
    return true
  }

  const submitLoginForm = async (
    formEl: FormInstance | undefined,
    loginForm: LoginFormType
  ) => {
    errorMessage.value = ''

    const isValid = await validateLoginForm(formEl)
    if (!isValid) return

    loading.value = true

    const result = (await loginHandler(loginForm)) as ApiResult<TokenData>
    const success = await processLoginResponse(result)

    if (!success) {
      loading.value = false
      return
    }

    await handleSuccessfulLogin()
    loading.value = false
  }

  return {
    loginHandler,
    getProfileHandler,
    setToken,
    setProfileToStore,
    submitLoginForm,
    handleLoginError,
    loading,
    errorMessage
  }
}
